<template>
	<div class="blog-list">

		<BlogFilters @filteredBlogs="updateBlogList"/>

		<h1 v-if="primaryBlog || secondaryBlogs.length" class="section-title">Featured</h1>

		<div class="featured-blogs" v-if="primaryBlog || secondaryBlogs.length">
			<v-hover v-slot="{ hover }">
				<div class="primary-blog" 
					v-if="primaryBlog"
					@click="showBlogDetails(primaryBlog)" 
					@mouseover="hoverBlog(primaryBlog)"
					@mouseleave="unhoverBlog(primaryBlog)"
					:class="{ 'expanded': expandedBlogId === primaryBlog.sys.id }"
					:ref="`blog-${primaryBlog.sys.id }`"
				>
					<img :src="primaryBlog.fields.image.fields.file.url" alt="Primary blog image" class="blog-card-image" />
					<div class="blog-card-content" :class="{ 'blog-card-content-expanded': hover || (isMobile && expandedBlogId === primaryBlog.sys.id) }" >
					<h2 class="blog-card-title">{{ primaryBlog.fields.title }}</h2>
					<v-expand-transition>
						<div v-if="hover || (isMobile && expandedBlogId === primaryBlog.sys.id)" class="expandable-content">
							<div
								class="d-flex transition-fast-in-fast-out darken-2 v-card--reveal text-h2"
								style="height: 100%;"
							>
								<div class="blog-details">
									<p class="blog-card-excerpt" v-html="primaryBlog.fields.excerpt"></p>
									<span class="blog-card-excerpt">{{ primaryBlog.fields.minutesToRead }} min read</span>
								</div>
							</div>
						</div>
					</v-expand-transition>
					</div>
				</div>
			</v-hover>

			<div class="secondary-blogs" v-if="secondaryBlogs.length">
				<div v-for="(blogPost) in secondaryBlogs" :key="blogPost.sys.id" 
					class="secondary-blog" 
					@click="showBlogDetails(blogPost)"
					:class="{ 'expanded': expandedBlogId === blogPost.sys.id }"
					:ref="`blog-${blogPost.sys.id }`"
				>
					<v-hover v-slot="{ hover }">
						<div>
							<img :src="blogPost.fields.image.fields.file.url" alt="Secondary blog image" class="blog-card-image" />
							<div class="blog-card-content" :class="{ 'blog-card-content-expanded': hover || (isMobile && expandedBlogId === blogPost.sys.id) }" >
								<h2 class="blog-card-title">{{ blogPost.fields.title }}</h2>
								<v-expand-transition>
									<div v-if="hover || (isMobile && expandedBlogId === blogPost.sys.id)" class="expandable-content">
										<div
											class="d-flex transition-fast-in-fast-out darken-2 v-card--reveal text-h2"
											style="height: 100%;"
										>
											<div class="blog-details">
												<p class="blog-card-excerpt" v-html="blogPost.fields.excerpt"></p>
												<span class="blog-card-excerpt">{{ blogPost.fields.minutesToRead }} min read</span>
											</div>
										</div>
									</div>
								</v-expand-transition>
							</div>
						</div>
					</v-hover>
				</div>
			</div>
		</div>

		<h1 v-if="recentBlogs.length" class="section-title">Recent</h1>

		<div class="recent-blogs">
			<div v-for="(blogPost) in recentBlogs" :key="blogPost.sys.id" 
				class="recent-blog-card"  
				@click="showBlogDetails(blogPost)" 
				@mouseover="hoverBlog(blogPost)" 
				@mouseleave="unhoverBlog(blogPost)"
				:class="{ 'expanded': expandedBlogId === blogPost.sys.id }"
				:ref="`blog-${blogPost.sys.id }`"
			>
				<v-hover v-slot="{ hover }">
					<div>
						<img :src="blogPost.fields.image.fields.file.url" alt="Blog image" class="blog-card-image" />
						<div class="blog-card-content" :class="{ 'blog-card-content-expanded': hover || (isMobile && expandedBlogId === blogPost.sys.id) }" >
						<h2 class="blog-card-title">{{ blogPost.fields.title }}</h2>
						<v-expand-transition>
						<div v-if="hover || (isMobile && expandedBlogId === blogPost.sys.id)" class="expandable-content">
							<div
								class="d-flex transition-fast-in-fast-out darken-2 v-card--reveal text-h2"
								style="height: 100%;"
							>
								<div class="blog-details">
									<p class="blog-card-excerpt" v-html="blogPost.fields.excerpt"></p>
									<span class="blog-card-excerpt">{{ blogPost.fields.minutesToRead }} min read</span>
								</div>
							</div>
						</div>
					</v-expand-transition>
					</div>
					</div>
				</v-hover>
			</div>
		</div>
	</div>
</template>

<script>
import { fetchBlogs } from '@/contentful/contentful';
import BlogFilters from "@/components/BlogFilters.vue";


export default {
	components: { BlogFilters },
	data() {
		return {
			blogs: [],
			primaryBlog: null,
			secondaryBlogs: [],
			recentBlogs: [],
			isMobile: false,
			expandedBlogId: null,
		};
	},

	methods: {
		updateBlogList(filteredBlogs) {
			this.blogs = filteredBlogs?.length > 0 ? filteredBlogs : this.$store.state.blogs
			this.categorizeBlogs()
		},
		showBlogDetails(blog) {
			if (blog.fields.title === 'Coming Soon...') {
				return
			}
			this.$router.push({ name: 'blogDetail', query: { blogId: blog.sys.id } })
			window.scrollTo(0, 1)
		},
		hoverBlog(blog) {
			blog.flipped = true;
		},
		unhoverBlog(blog) {
			blog.flipped = false;
		},

		categorizeBlogs() {
			this.primaryBlog = this.blogs.find(blog => blog.fields.isPrimary);
			this.secondaryBlogs = this.blogs.filter(blog => blog.fields.isSecondary);
			this.recentBlogs = this.blogs.filter(blog => !blog.fields.isPrimary && !blog.fields.isSecondary);
		},
		onScroll() {
			const blogsToCheck = [this.primaryBlog, ...this.secondaryBlogs, ...this.recentBlogs];
			let found = false
			for (let blog of blogsToCheck) {
				if (this.isInView(blog.sys.id)) {
					this.expandedBlogId = blog.sys.id;
					found = true;
					break;
				}
			}
			// If no blog is found in view, expand the primary blog by default
			if (!found && this.isMobile) {
				this.expandedBlogId = this.primaryBlog.sys.id;
			}

			// if the user is at the top of the page, no blog should be expanded
			if (window.scrollY === 0) {
				this.expandedBlogId = null;
			}
		},
		checkIfMobile() {
			this.isMobile = window.innerWidth <= 768;
		},
		isInView(blogId) {
			const element = this.$refs[`blog-${blogId}`][0];
			if (!element) return false;

			const rect = element.getBoundingClientRect();

			return (
				rect.top >= 0 &&
				rect.left >= 0 &&
				rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
				rect.right <= (window.innerWidth || document.documentElement.clientWidth)
				// rect.top < window.innerHeight && // Check if the top of the element is above the bottom of the viewport
				// rect.bottom > 0 // Check if the bottom of the element is below the top of the viewport
			);
		},
	},

	async created () {
		this.$store.commit('setLoading', true)
		this.blogs = this.$store?.state?.blogs ? this.$store.state.blogs : await fetchBlogs('blogPost');
		this.$store.commit('setBlogs', this.blogs)
		this.categorizeBlogs();
		this.$store.commit('setLoading', false)
		this.checkIfMobile();
		window.addEventListener('scroll', this.onScroll);
		window.addEventListener('resize', this.checkIfMobile);
	},

	beforeDestroy() {
		window.removeEventListener('scroll', this.onScroll);
		window.removeEventListener('resize', this.checkIfMobile);
	},
};
</script>

<style scoped>
	/* eslint-disable no-mixed-spaces-and-tabs */
	.section-title {
	font-size: 2rem;
	font-weight: bold;
	margin-bottom: 2rem;
	text-align: left;
	padding-left: 1rem;
	}
	
	.blog-list {
	padding: 2rem 0;
	max-width: 1200px;
	/* Set max-width to keep content centered */
	margin: 0 auto;
	/* Center content horizontally */
	}

	.featured-blogs {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	margin-bottom: 3rem;
	}

	.primary-blog {
	flex: 1 1 60%;
	margin-right: 2%;
	margin-bottom: 2rem;
	position: relative;
	transition: all 0.3s ease-in-out;
	cursor: pointer;
	border-radius: 15px;
	overflow: hidden;
	box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1);
	height: 600px; /* Increased height to match combined height of secondary cards */
	}

	.primary-blog:hover {
	transform: translateY(-5px);
	box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
	}

	.primary-blog .blog-card-image {
	height: 85%; /* Adjust image height to leave room for content */
	object-fit: cover;
	border-radius: 15px 15px 0 0; /* Only round the top corners since it fills the container */
	}

	.secondary-blogs {
	flex: 1 1 38%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 600px; /* Match the height of the primary blog */
	}

	.secondary-blog {
	flex: 1;
	margin-bottom: 20px; /* Specific margin to ensure proper spacing */
	position: relative;
	transition: transform 0.3s ease-in-out;
	cursor: pointer;
	border-radius: 15px;
	overflow: hidden;
	box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1);
	height: calc(50% - 10px); /* Take up half the container minus spacing */
	}

	.secondary-blog:last-child {
	margin-bottom: 0; /* Remove margin from last item */
	}

	.secondary-blog:hover {
	transform: translateY(-5px);
	box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
	}

	.secondary-blog .blog-card-image {
	height: 65%; /* Adjust image height to leave room for content */
	object-fit: cover;
	border-radius: 15px 15px 0 0;
	}

	.recent-blogs {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
	/* grid-template-columns: repeat(3, minmax(300px, 1fr)); */
	gap: 2rem;
	/* Add spacing between grid items */
	}

	.recent-blog-card {
	margin-bottom: 2rem;
	/* Add vertical spacing */
	position: relative;
	transition: transform 0.3s ease-in-out;
	cursor: pointer;
	border-radius: 15px;
	overflow: hidden;
	box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1);
	height: 400px; /* Set a fixed height for recent blog cards */
	width: 100%;
	margin-bottom: 1rem;
	}

	.recent-blog-card:hover {
	transform: translateY(-5px);
	box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
	}

	.recent-blog-card .blog-card-image {
	height: 60%;
	object-fit: cover;
	border-radius: 15px 15px 0 0;
	}

	.blog-card-image {
	width: 100%;
	height: 300px;
	object-fit: cover;
	border-radius: 15px;
	box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
	transition: transform 0.3s ease-in-out;
	}

	/* use this class on the image of the primary blog for a different style of the entire view: primary image fits the card and there are three columns on the recent blogs list */
	.blog-card-image-primary {
	width: 100%;
	height: fit-content;
	object-fit: cover;
	border-radius: 15px;
	box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
	transition: transform 0.3s ease-in-out;
	}

	.blog-card-content {
	position: absolute;
	bottom: 20px;
	left: 20px;
	right: 20px;
	background-color: rgba(255, 255, 255, 0.9);
	padding: 1rem;
	border-radius: 10px;
	text-align: left;
	box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
	}

	/* Specific styling for primary blog content */
	.primary-blog .blog-card-content {
	bottom: 0;
	left: 0;
	right: 0;
	border-radius: 0;
	background-color: rgba(255, 255, 255, 0.9);
	padding: 1.5rem;
	}

	.primary-blog .expandable-content {
	width: 100%;
	}

	.primary-blog .blog-details {
	width: 100%;
	}

	.blog-card-content-expanded {
	 background-color: rgba(255, 255, 255)		
	}

	.blog-card-title {
	font-size: 1.2rem;
	font-weight: bold;
	margin-bottom: 0.5rem;
	}

	.blog-card-excerpt {
	font-size: 1rem;
	line-height: 1.5;
	color: #555;
	}

	@media (max-width: 768px) {
	.blog-list {
		padding: 1rem;
	}

	.featured-blogs,
	.recent-blogs {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.primary-blog,
	.secondary-blogs {
		width: 100%;
		margin-right: 0;
	}

	.primary-blog {
		height: 450px; /* Adjusted height for mobile */
	}

	.secondary-blogs {
		height: auto; /* Allow natural height on mobile */
	}

	.secondary-blog {
		height: 350px; /* Fixed height for mobile */
		width: 100%;
		margin-bottom: 1rem;
		flex: none; /* Remove flex property on mobile */
	}

	.recent-blogs {
		grid-template-columns: 1fr;
		gap: 1rem;
	}

	.post-card-meta-length {
		position: absolute;
		bottom: 0;
		right: 0;
		color: #555;
	}

	.blog-details {
		background-color: rgba(255, 255, 255, 0.9);
		padding: 1rem;
		border-radius: 10px;
		text-align: left;
		box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
	}

	/* .v-card--reveal {
		align-items: center;
		bottom: 0;
		justify-content: center;
		opacity: .5;
		position: absolute;
		width: 100%;
	} */
	}

	.post-card-primary-tag {
		background-color: #f0f0f0;
		color: #999;
		font-size: 0.8rem;
		margin-right: 0.5rem;
		padding: 0.25rem ;
		border-radius: 5px;
	}

	.post-card-tags {
		display: flex;
		flex-wrap: wrap;
		margin-bottom: 1rem;
	}

	/* this will limit the amount of lines shown in the expanded detail view of the blog and will automatically clip and add three dots to the end of the text */
	p {
	width: 100%;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 12;
	overflow: hidden;
	}

	/* @media (max-width: 768px) {
		.expanded .expandable-content {
			display: block !important;
		}
	} */

</style>